import {
  CheckCircleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useHouseholds } from "@/api/hooks/useHouseholds.ts";
import { HouseholdWithOwnership } from "@/api/rest/models/household";
import { Case, CaseStatus, CaseType } from "@/api/rest/models/warranty";
import { caseTypeNamespaceMap } from "@/utils/translation";

export default function ListClaims({
  cases,
  showHouseholds = false,
  caseType,
  availableAccountIds = [],
  buildingOwnerId, // Add this new prop
}: {
  cases: Case[];
  showHouseholds?: boolean;
  caseType?: CaseType;
  availableAccountIds?: number[];
  buildingOwnerId?: number; // Building owner account ID for highlighting
}) {
  const navigate = useNavigate();
  const households = useHouseholds();
  const householdsbyId = useMemo(() => {
    return households.reduce(
      (acc, h) => {
        acc[h.id] = h;
        return acc;
      },
      {} as Record<number, HouseholdWithOwnership>,
    );
  }, [households]);
  const { t } = useTranslation();
  const namespace = caseType ? caseTypeNamespaceMap.get(caseType) : "case";

  // Determine if current user is the building owner
  const amIBuildingOwner = useMemo(() => {
    if (!buildingOwnerId || availableAccountIds.length === 0) {
      return false;
    }
    return availableAccountIds.includes(buildingOwnerId);
  }, [buildingOwnerId, availableAccountIds]);

  console.log("buildingOwnerId", buildingOwnerId);
  console.log("availableAccountIds", availableAccountIds);
  console.log("amIBuildingOwner", amIBuildingOwner);

  const sortedCases = useMemo(() => {
    // Define status priority order
    const statusOrder = {
      [CaseStatus.BACKLOG]: 1,
      [CaseStatus.NOT_DONE]: 2,
      [CaseStatus.TO_DO]: 3,
      [CaseStatus.IN_PROGRESS]: 4,
      [CaseStatus.REVIEW]: 5,
      [CaseStatus.WAITING]: 6,
      [CaseStatus.SOLVED]: 7,
      [CaseStatus.CLOSED]: 8,
    };

    return cases.slice().sort((a, b) => {
      const statusDiff = statusOrder[a.status] - statusOrder[b.status];
      if (statusDiff !== 0) {
        return statusDiff;
      }

      // Then sort by timestamp (descending)
      return (b.createdAt || "").localeCompare(a.createdAt || "");
    });
  }, [cases]);

  const caseTypeRoutes = {
    [CaseType.WARRANTY]: "warranty",
    [CaseType.PROPERTY_MANAGER_CASE]: "property-manager-case",
  };

  return (
    <>
      <h3 className="text-xl font-semibold">
        {t(`${namespace}.submittedCases`)}
      </h3>
      {sortedCases?.map((c) => {
        // Check if last message is from building owner
        const lastMessageFromBuildingOwner =
          buildingOwnerId && c.lastMessageAccountId === buildingOwnerId;
        // Determine if case should be highlighted
        const shouldHighlight =
          c.lastMessageAccountId &&
          c.status !== CaseStatus.CLOSED &&
          c.status !== CaseStatus.SOLVED &&
          // I'm building owner and message is not from one of my accounts
          ((amIBuildingOwner &&
            !availableAccountIds.includes(c.lastMessageAccountId)) ||
            // I'm not building owner and message is from building owner
            (!amIBuildingOwner && lastMessageFromBuildingOwner));

        return (
          <div
            key={c.id}
            className={`flex flex-col cursor-pointer rounded-md py-2 px-4 ${
              shouldHighlight
                ? "bg-orange-200 hover:bg-orange-300"
                : "bg-white hover:bg-orange-100"
            }`}
            onClick={() => navigate(`/${caseTypeRoutes[c.type]}/${c.id}`)}
          >
            {showHouseholds && (
              <span>
                <span className="font-bold">
                  {householdsbyId[c.householdId]?.title || ""}
                </span>{" "}
                {c.title}
              </span>
            )}
            {!showHouseholds && <span className="font-bold">{c.title}</span>}
            <div className="flex flex-row text-gray-500 text-sm items-center">
              {c.status === CaseStatus.SOLVED && (
                <CheckCircleIcon className="h-4 w-4 mr-1 inline text-green-500" />
              )}
              {shouldHighlight && (
                <InformationCircleIcon className="h-4 w-4 mr-1 inline text-dobu-orange" />
              )}
              {c.status === CaseStatus.CLOSED && (
                <MinusCircleIcon className="h-4 w-4 mr-1 inline text-red-600" />
              )}
              <span>{t(c.localisedStatus)}</span>
              <span className="flex-grow" />
              <span>{new Date(c.createdAt).toLocaleDateString()}</span>
            </div>
          </div>
        );
      })}
    </>
  );
}
