import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useMemo } from "react";

import { AccountInfo } from "@/api/rest/models/account";
import {
  EntityConfigurationType,
  useBuildingConfiguration,
} from "@/api/rest/services/configurationService";
import ConfigurationEditor from "@/components/building/ConfigurationEditor";
import Spinner from "@/components/common/Spinner";

// Base configuration types available to all users
const baseConfigurationTypes = new Set([
  EntityConfigurationType.WARRANTY_PROMPT,
  EntityConfigurationType.WELCOME_EMAIL,
  EntityConfigurationType.PROPERTY_MANAGER_CASE_PROMPT,
]);

// Additional configuration types for Dobu employees
const dobuEmployeeConfigTypes = new Set([
  ...baseConfigurationTypes,
  EntityConfigurationType.QUESTIONS_PROMPT,
  EntityConfigurationType.TASK_DEFINITION_PROMPT,
  EntityConfigurationType.HAUSING_TICKET_DESCRIPTION,
  EntityConfigurationType.WARRANTY_AUTO_REPLY_MESSAGE,
]);

export default function BuildingSettingsPage() {
  const { t } = useTranslation();
  const { buildingId } = useParams<{ buildingId: string }>();
  const navigate = useNavigate();

  // Get account info from context
  const { account } = useOutletContext<{ account: AccountInfo }>();

  const {
    data: buildingConfiguration,
    isLoading,
    error,
    refetch: refreshBuildingConfiguration,
  } = useBuildingConfiguration(Number(buildingId));

  // Determine which configuration types to show based on user's email
  const allowedConfigurationTypes = useMemo(() => {
    const userEmail = account?.email?.toLowerCase();
    return userEmail?.endsWith("dobu.ee")
      ? dobuEmployeeConfigTypes
      : baseConfigurationTypes;
  }, [account?.email]);

  if (isLoading) {
    return (
      <div className="flex flex-grow items-center justify-center">
        <Spinner className="w-8 h-8" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-grow items-center justify-center text-red-600">
        {t("common.error.loading")}
      </div>
    );
  }

  return (
    <main className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white items-center">
      <div className="flex flex-grow self-stretch flex-col">
        <div className="flex flex-row">
          <ChevronLeftIcon
            className="h-9 w-9 mr-2 hover:bg-slate-300 focus-visible:bg-dobu-orange cursor-pointer rounded"
            onClick={() => navigate(-1)}
          />
          <h2 className="text-3xl font-bold text-center mr-4">
            {t("settings.title")}
          </h2>
        </div>

        {/* Add section title for Dobu employees */}
        {account?.email?.toLowerCase().endsWith("dobu.ee") && (
          <div className="mt-4 mb-2 text-gray-600">Dobu employee settings</div>
        )}

        <div className="flex flex-col gap-8 mt-8">
          {buildingConfiguration
            ?.filter((conf) => allowedConfigurationTypes.has(conf.type))
            .map((conf) => (
              <ConfigurationEditor
                conf={conf}
                key={conf.id}
                refreshConf={refreshBuildingConfiguration}
              />
            ))}
        </div>
      </div>
    </main>
  );
}
