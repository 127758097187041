import { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { Document } from "@/api/rest/models/document";
import { getDocumentUrl2 } from "@/api/rest/services/documentService";
import ManagedPopup from "@/components/common/ManagedPopup";
import { PopupContentSize } from "@/components/common/PopupSizeUtils";

interface PreviewModalProps {
  document: Document;
  contentSize?: PopupContentSize;
  trigger: ReactNode;
  onDownload: (url: string, originalName: string) => void;
  onDelete?: (documentId: number) => Promise<void>;
  children: ReactNode;
  className?: string;
}

export const PreviewModal: React.FC<PreviewModalProps> = ({
  document,
  contentSize,
  trigger,
  onDownload,
  onDelete,
  children,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <ManagedPopup
      className={className}
      trigger={trigger}
      contentSize={contentSize}
      title={
        <>
          <span className="break-all pr-3">{document.fileName}</span>
          <a
            className="rounded text-sm leading-6 px-2 mx-2 bg-gray-500 text-slate-100 hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus cursor-pointer self-baseline"
            onClick={() =>
              onDownload(getDocumentUrl2(document), document.originalFilename)
            }
          >
            {t("files.download")}
          </a>
          {onDelete && (
            <button
              className="rounded text-sm leading-6 px-2 mx-2 bg-orange-100 text-slate-100 hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus cursor-pointer self-baseline"
              onClick={() => onDelete(document.id)}
            >
              {t("files.remove")}
            </button>
          )}
          <span className="flex-grow" />
        </>
      }
    >
      {children}
    </ManagedPopup>
  );
};
