import { apiBase, fetchUsingApi } from "../core/apiClient";
import { ApiResponse } from "../core/types";
import { NotificationSettings } from "../models/notification";

// Fetch notification settings from API
export const fetchNotificationSettings = async (): Promise<
  ApiResponse<NotificationSettings>
> => {
  // // Returning mock data until real API is implemented
  // return {
  //   success: true,
  //   result: {
  //     deliveryMethods: {
  //       mobileApp: true,
  //       email: false,
  //     },
  //     notificationTypes: {
  //       maintenanceReminders: true,
  //       warrantyMessages: true,
  //     },
  //   },
  // };
  return await fetchUsingApi(
    `${apiBase}/api/v1/notifications/settings`,
    "GET",
    (data) => data as NotificationSettings,
  );
};

// Update notification settings
export const updateNotificationSettings = async (
  settings: NotificationSettings,
): Promise<ApiResponse<boolean>> => {
  // Simulate successful API response
  // console.log("Settings to be saved:", settings);
  // return {
  //   success: true,
  //   result: true,
  // };
  return await fetchUsingApi(
    `${apiBase}/api/v1/notifications/settings`,
    "PUT",
    () => true,
    JSON.stringify(settings),
  );
};
