import { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { Document } from "@/api/rest/models/document";
import { Product } from "@/api/rest/models/product";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";
import ProductOverview from "@/components/household-contents/ProductOverview.tsx";

export default function ProductButton({
  children,
  className,
  product,
  documents,
  updateHousehold,
}: {
  children: ReactNode;
  className?: string;
  product: Product;
  documents: Document[];
  updateHousehold: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ManagedPopup
      trigger={children}
      triggerClassname={className}
      contentSize="data"
      title={
        <>
          <span className="break-all">{product.name}</span>
          <a
            className="rounded text-sm leading-6 px-2 mx-2 bg-gray-500 text-slate-100 hover:bg-dobu-orange-focus focus-visible:bg-dobu-orange-focus cursor-pointer self-baseline"
            onClick={() =>
              navigate(
                `/household/${product.householdId}/product/${product.id}`,
              )
            }
          >
            {t("product.change")}
          </a>
          <div className="flex-grow" />
        </>
      }
    >
      <ProductOverview
        product={product}
        documents={documents}
        updateHousehold={updateHousehold}
      />
    </ManagedPopup>
  );
}
