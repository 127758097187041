import { ReactNode, useCallback } from "react";

import { QueryObserverResult } from "@tanstack/react-query";

import {
  downloadDocument2,
  getDocumentUrl2,
} from "@/api/rest/services/documentService";

import { Document } from "@/api/rest/models/document";
import { deleteDocument } from "@/api/rest/services/documentService";

import { PopupContentSize } from "@/components/common/PopupSizeUtils";

import { DocumentViewer } from "./DocumentViewer";
import { PreviewModal } from "./DocumentViewer/PreviewModal";
import { isPreviewableDocument } from "./DocumentViewer/types";
import { is360Image } from "./DocumentViewer/viewers/Sphere360Viewer";

interface DocumentsPreviewProps {
  document: Document;
  buildingId?: number;
  householdId?: number;
  className?: string;
  children: ReactNode;
  onSelect: (url: string, originalName: string) => void;
  allowPreview: boolean;
  allowDelete?: boolean;
  updateHousehold?: () => Promise<QueryObserverResult>;
}

export const DocumentsPreview = ({
  document,
  buildingId,
  householdId,
  className,
  children,
  onSelect,
  allowPreview,
  allowDelete = false,
  updateHousehold,
}: DocumentsPreviewProps) => {
  const handleDelete = useCallback(
    async (documentId: number) => {
      if (allowDelete && buildingId && householdId) {
        try {
          await deleteDocument(buildingId, householdId, documentId);
          await updateHousehold?.();
        } catch (error) {
          console.error("Failed to delete document:", error);
        }
      }
    },
    [allowDelete, buildingId, householdId, updateHousehold],
  );

  const handleSelect = useCallback(
    (url: string, originalName: string) => {
      if (onSelect) {
        onSelect(url, originalName);
      } else {
        downloadDocument2(url, originalName);
      }
    },
    [onSelect],
  );

  if (!allowPreview || !isPreviewableDocument(document)) {
    return (
      <div
        className={className}
        onClick={() =>
          handleSelect(getDocumentUrl2(document), document.originalFilename)
        }
      >
        {children}
      </div>
    );
  }

  const getContentSize = (document: Document): PopupContentSize => {
    if (!document) {
      return "default";
    }

    if (is360Image(document)) {
      return "panorama";
    }

    if (document.mimeType?.startsWith("image/")) {
      return "image";
    }

    return "document";
  };

  return (
    <PreviewModal
      contentSize={getContentSize(document)}
      document={document}
      trigger={<div className={className}>{children}</div>}
      onDownload={handleSelect}
      onDelete={allowDelete ? handleDelete : undefined}
    >
      <DocumentViewer
        document={document}
        buildingId={buildingId}
        householdId={householdId}
        className="flex-grow"
        allowPreview={allowPreview}
      />
    </PreviewModal>
  );
};
