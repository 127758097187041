export type PopupContentSize =
  | "document" // PDF, Word docs - full height
  | "image" // Regular images - constrained height
  | "panorama" // 360° images - specific height
  | "data" // Info displays - content height
  | "default"; // Default fallback

export const getPopupSizeClasses = (size: PopupContentSize): string => {
  const baseClasses =
    "mx-auto md:rounded-lg bg-white flex flex-col shadow-xl overflow-clip " +
    "min-w-full min-h-full max-h-full max-w-full " +
    "md:min-w-[50%] md:max-w-[75%] md:max-h-[80vh]";

  switch (size) {
    case "document":
      return baseClasses;
    case "image":
      return `${baseClasses} md:min-h-[80vh]`;
    case "panorama":
      return `${baseClasses} md:min-h-[30vh]`;
    case "data":
      return `${baseClasses} md:min-h-0`;
    default:
      return `${baseClasses} md:min-h-[80vh]`;
  }
};
