import { ReactNode, useState } from "react";

import { Document } from "@/api/rest/models/document";
import { HouseholdData } from "@/api/rest/models/household";
import UnmanagedPopup from "@/components/common/UnmanagedPopup.tsx";
import DocumentsList from "@/components/household-contents/document/DocumentsList";

export default function AddFilesPopup({
  householdData,
  addDocument,
  children,
  triggerClassName,
}: {
  householdData: HouseholdData;
  addDocument: (doc: Document) => void;
  children: ReactNode;
  triggerClassName?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const submitDocument = (doc: Document) => {
    addDocument(doc);
    setIsOpen(false);
  };

  return (
    <UnmanagedPopup
      title="Lisa dokumendid"
      isOpen={isOpen}
      closePopup={() => setIsOpen(false)}
      trigger={
        <span onClick={() => setIsOpen(true)} className={triggerClassName}>
          {children}
        </span>
      }
    >
      <div className="flex flex-col items-stretch overflow-y-auto gap-2 p-4">
        <DocumentsList
          documents={householdData.documents}
          buildingId={householdData.building.id}
          itemsClassName="bg-slate-100"
          allowDownload={false}
          onSelect={submitDocument}
        />
      </div>
    </UnmanagedPopup>
  );
}
