import dayjs from "dayjs";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { apiBase } from "./api/rest/core/apiClient";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    supportedLngs: ["en", "et", "lv"],
    debug: process.env.NODE_ENV === "development",

    detection: {
      order: ["querystring", "localStorage", "navigator"],
      caches: ["localStorage"],
    },

    backend: {
      // Specify the path to your language JSON files without namespaces.
      loadPath: apiBase + "/api/v1/translations/{{lng}}.json",
    },

    interpolation: {
      escapeValue: false, // React already escapes values.
    },

    // No namespaces are defined since your server sends a single JSON per language.
  })
  .then(() => {
    // Sync dayjs locale with i18next's current language.
    dayjs.locale(i18n.language);
  });

export default i18n;
