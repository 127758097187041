// src/components/common/UnmanagedPopup.tsx

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

import { PopupContentSize, getPopupSizeClasses } from "./PopupSizeUtils";

interface UnmanagedPopupProps {
  trigger: ReactNode;
  title: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  closePopup: () => void;
  contentSize?: PopupContentSize;
  className?: string;
}

export default function UnmanagedPopup({
  trigger,
  title,
  children,
  isOpen,
  closePopup,
  contentSize = "default",
  className,
}: UnmanagedPopupProps) {
  return (
    <>
      {trigger}
      <Dialog open={isOpen} onClose={closePopup} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center md:p-4">
          <DialogPanel
            className={`${getPopupSizeClasses(contentSize)} ${className ?? ""}`}
          >
            <DialogTitle className="text-lg p-6 bg-slate-100 font-medium leading-6 text-gray-900 flex flex-row justify-between">
              {title}
              <XCircleIcon
                className="h-6 w-6 min-w-6 min-h-6 text-gray-500 hover:text-dobu-orange-focus focus-visible:text-dobu-orange-focus cursor-pointer"
                onClick={closePopup}
              />
            </DialogTitle>

            {children}
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
