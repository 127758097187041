import { UserPlusIcon } from "@heroicons/react/24/outline";
import { FormEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AccountType,
  AssignAccountRequest,
  AssignAccountResponse,
} from "@/api/rest/models/account";
import { HouseholdData } from "@/api/rest/models/household";
import {
  assignToHousehold,
  unassignUser,
} from "@/api/rest/services/householdService";
import AccountsTable from "@/components/AccountsTable.tsx";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";
import { sendToSentry } from "@/utils/sentry";

import ConfirmationDialog from "../common/ConfirmationDialog";

interface FormValues {
  type: AccountType;
  identifier: string;
  name?: string;
  email?: string;
}

export default function HouseholdSettingsPopup({
  householdData,
  updateHousehold,
}: {
  householdData: HouseholdData;
  updateHousehold: () => void;
}) {
  const [newAccount, setNewAccount] = useState<AssignAccountResponse | null>(
    null,
  );
  const [selectedType, setSelectedType] = useState<AccountType>(
    AccountType.INDIVIDUAL,
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null,
  );
  const { t } = useTranslation();

  const unassignAccount = async (accountId: number) => {
    await unassignUser(householdData.household.id, accountId);
    updateHousehold();
  };

  const handleUnassignRequest = (accountId: number) => {
    setSelectedAccountId(accountId);
    setIsConfirmationOpen(true);
  };

  const handleConfirmUnassign = async () => {
    if (selectedAccountId !== null) {
      await unassignAccount(selectedAccountId);
      setIsConfirmationOpen(false);
      setSelectedAccountId(null);
    }
  };

  const handleCancelUnassign = () => {
    setIsConfirmationOpen(false);
    setSelectedAccountId(null);
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = Object.fromEntries(
      new FormData(e.currentTarget).entries(),
    ) as unknown as FormValues;

    const data: AssignAccountRequest = {
      type: formData.type,
      identifier: formData.identifier,
    };

    if (formData.type === AccountType.INDIVIDUAL && formData.name) {
      data.additional = { name: formData.name };
    } else if (
      formData.type === AccountType.ORGANIZATION &&
      formData.name &&
      formData.email
    ) {
      data.additional = { name: formData.name, email: formData.email };
    }

    try {
      const result = await assignToHousehold(householdData.household.id, data);
      if (result === AssignAccountResponse.SUCCESS) {
        updateHousehold();
        setNewAccount(null);
      } else {
        setNewAccount(result);
      }
    } catch (e) {
      sendToSentry(e as Error, {
        additionalContext: { context: "HouseholdSettingsPopup.submitForm" },
      });
      console.log(e);
    }
  };

  const householdMembers = useMemo(() => {
    return householdData.accounts.filter(
      (account) => account.id !== householdData.building.accountId,
    );
  }, [householdData]);

  return (
    <>
      <ManagedPopup
        contentSize="data"
        trigger={
          <UserPlusIcon className="w-12 h-12 rounded p-2 hover:bg-gray-300 text-gray-500" />
        }
        title={t("settings.title") + ": " + householdData.household.title}
      >
        <div className="flex flex-col overflow-hidden m-4">
          <span className="text-2xl font-bold pb-4">
            {t("settings.accesses")}
          </span>
          <AccountsTable
            accounts={householdMembers}
            showAddAccount={newAccount === null}
            addAccount={() => setNewAccount(AssignAccountResponse.SUCCESS)}
            unassignAccount={handleUnassignRequest}
          />
        </div>
        {newAccount !== null && (
          <form className="flex flex-col m-4" onSubmit={submitForm}>
            <span className="text-2xl font-bold pb-4">
              {t("settings.addNewAccess")}
            </span>
            <div className="flex flex-row gap-4 pb-4 flex-wrap md:flex-nowrap">
              <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                <label className="text-gray-500" htmlFor="type">
                  {t("settings.type")}
                </label>
                <select
                  className="border border-gray-300 rounded-md p-2 w-full"
                  name="type"
                  required
                  value={selectedType}
                  onChange={(e) =>
                    setSelectedType(e.target.value as AccountType)
                  }
                >
                  <option value={AccountType.INDIVIDUAL}>
                    {t("settings.individual")}
                  </option>
                  <option value={AccountType.ORGANIZATION}>
                    {t("settings.organization")}
                  </option>
                </select>
              </div>
              <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                <label className="text-gray-500" htmlFor="registryCode">
                  {selectedType === AccountType.INDIVIDUAL
                    ? t("settings.email")
                    : t("settings.registryCode")}
                </label>
                <input
                  className="border border-gray-300 rounded-md p-2 w-full"
                  name="identifier"
                  type={
                    selectedType === AccountType.INDIVIDUAL ? "email" : "number"
                  }
                  placeholder={
                    selectedType === AccountType.INDIVIDUAL
                      ? t("settings.email")
                      : t("settings.registryCode")
                  }
                  required
                />
              </div>
            </div>
            {newAccount === AssignAccountResponse.MORE_INFO_REQUIRED && (
              <div className="flex flex-row gap-4 pb-4 flex-wrap md:flex-nowrap">
                {selectedType === AccountType.INDIVIDUAL ? (
                  <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                    <label className="text-gray-500" htmlFor="name">
                      {t("settings.name")}
                    </label>
                    <input
                      className="border border-gray-300 rounded-md p-2 w-full"
                      name="name"
                      type="text"
                      placeholder={t("settings.name")}
                      required
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                      <label className="text-gray-500" htmlFor="name">
                        {t("settings.name")}
                      </label>
                      <input
                        className="border border-gray-300 rounded-md p-2 w-full"
                        name="name"
                        type="text"
                        placeholder={t("settings.name")}
                        required
                      />
                    </div>
                    <div className="flex flex-col flex-grow basis-full md:basis-1/2">
                      <label className="text-gray-500" htmlFor="email">
                        {t("settings.email")}
                      </label>
                      <input
                        className="border border-gray-300 rounded-md p-2 w-full"
                        name="email"
                        type="email"
                        placeholder={t("settings.email")}
                        required
                      />
                    </div>
                  </>
                )}
              </div>
            )}
            <button className="rounded-md p-2 flex-grow bg-blue-500 text-white hover:bg-blue-600">
              {t("settings.save")}
            </button>
          </form>
        )}
      </ManagedPopup>

      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onConfirm={handleConfirmUnassign}
        onCancel={handleCancelUnassign}
        title={t("settings.confirmUnassignTitle")}
        message={t("settings.confirmUnassignMessage", {
          name: householdMembers.find(
            (account) => account.id === selectedAccountId,
          )?.name,
        })}
        confirmText={t("settings.confirmUnassign")}
        cancelText={t("common.cancel")}
      />
    </>
  );
}
