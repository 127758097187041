import { Dayjs } from "@/components/dayjsExtended.ts";

export enum CaseType {
  WARRANTY = "WARRANTY",
  PROPERTY_MANAGER_CASE = "PROPERTY_MANAGER_CASE",
}

// src/api/rest/models/warranty.ts
export interface CaseRaw {
  id: number;
  householdId: number;
  title: string;
  status: CaseStatus;
  createdAt: string;
  type: CaseType;
  lastMessageAccountId?: number;
  lastMessageTimestamp?: string;
}

export interface Case extends CaseRaw {
  createdAtTimestamp: Dayjs;
  localisedStatus: string;
}

export enum CaseStatus {
  SOLVED = "SOLVED",
  CLOSED = "CLOSED",
  BACKLOG = "BACKLOG",
  TO_DO = "TO_DO",
  WAITING = "WAITING",
  IN_PROGRESS = "IN_PROGRESS",
  REVIEW = "REVIEW",
  NOT_DONE = "NOT_DONE",
}

export const statusMap = new Map<CaseType, Map<CaseStatus, string>>([
  [
    CaseType.WARRANTY,
    new Map([
      [CaseStatus.BACKLOG, "warranty.caseStatus.backlog"],
      [CaseStatus.TO_DO, "warranty.caseStatus.toDo"],
      [CaseStatus.WAITING, "warranty.caseStatus.waiting"],
      [CaseStatus.IN_PROGRESS, "warranty.caseStatus.inProgress"],
      [CaseStatus.REVIEW, "warranty.caseStatus.review"],
      [CaseStatus.SOLVED, "warranty.caseStatus.solved"],
      [CaseStatus.CLOSED, "warranty.caseStatus.closed"],
      [CaseStatus.NOT_DONE, "warranty.caseStatus.notDone"],
    ]),
  ],
  [
    CaseType.PROPERTY_MANAGER_CASE,
    new Map([
      [CaseStatus.BACKLOG, "propertyManagerCase.caseStatus.backlog"],
      [CaseStatus.TO_DO, "propertyManagerCase.caseStatus.toDo"],
      [CaseStatus.WAITING, "propertyManagerCase.caseStatus.waiting"],
      [CaseStatus.IN_PROGRESS, "propertyManagerCase.caseStatus.inProgress"],
      [CaseStatus.REVIEW, "propertyManagerCase.caseStatus.review"],
      [CaseStatus.SOLVED, "propertyManagerCase.caseStatus.solved"],
      [CaseStatus.CLOSED, "propertyManagerCase.caseStatus.closed"],
      [CaseStatus.NOT_DONE, "propertyManagerCase.caseStatus.notDone"],
    ]),
  ],
]);

export interface CaseMessageRaw {
  id: number;
  warrantyCaseId: number;
  accountId: number;
  userId: number;
  userName: string;
  createdAt: string;
  content: string;
  type: CaseMessageType;
}

export interface CaseMessage extends CaseMessageRaw {
  createdAtTimestamp: Dayjs;
}

export enum CaseMessageType {
  TEXT = "TEXT",
  FILE = "FILE",
  IMAGE = "IMAGE",
  AI_HISTORY = "AI_HISTORY",
}

export interface CaseResponse {
  case: Case;
  messages: CaseMessage[];
}

export interface CaseMessageRequest {
  accountId: number;
  message: string;
}

export interface NewCaseRequest {
  question: string;
  householdId: number;
  startFresh: boolean;
  accountId: number;
  type: CaseType;
}

export interface SubmitCaseRequestWithoutFiles {
  title: string;
  description: string;
  householdId: number;
  accountId: number;
  phoneNumber: string | null;
  type: CaseType;
}

export interface UpdateCaseStatusRequest {
  buildingId: number;
  status: CaseStatus;
}

export enum NewWarrantyCaseResponseType {
  MORE_INFO_REQUIRED = "MORE_INFO_REQUIRED",
  WARRANTY = "WARRANTY",
  NO_WARRANTY = "NO_WARRANTY",
}

export interface NewWarrantyCaseResponse {
  status: NewWarrantyCaseResponseType;
  message: string;
  caseId: number | null;
  provisionalTitle: string | null;
}
