import { Router } from "@remix-run/router/dist/router";
import * as Sentry from "@sentry/react";

import { getToken, removeToken, setToken } from "@/utils/tokenManager";

import { isAndroidMobileApp } from "@/utils/platform";

import {
  createSpanAttributes,
  sendToSentry,
  updateSpanWithResponse,
} from "@/utils/sentry";

import {
  ApiResponse,
  AuthResponse,
  CustomRequestInit,
  FetchWithAuthOptions,
} from "./types";

export const apiBase =
  import.meta.env.MODE === "production"
    ? "https://api.dobu.ee"
    : isAndroidMobileApp()
      ? "http://10.0.2.2:8080"
      : "http://localhost:8080";

export let routerInstance: Router | null = null;
export const setRouterInstance = (router: Router) => {
  routerInstance = router;
};

export const isClientError = (status: number): boolean => {
  return status >= 400 && status < 500;
};

const parseResponseBody = async (response: Response): Promise<any> => {
  try {
    return await response.json();
  } catch {
    try {
      return await response.text();
    } catch {
      return null;
    }
  }
};

export const fetchUsingApi = async <Type>(
  url: string,
  method: string,
  resultProcessingFunction: (result: any) => Type,
  body?: BodyInit | null | undefined,
): Promise<ApiResponse<Type>> => {
  let response: Response | undefined;
  let responseBody: any;

  try {
    const headers: Record<string, string> = {};

    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    const options: CustomRequestInit = {
      method,
      headers,
      body,
    };

    response = await fetchWithAuth(url, options);

    if (response.ok) {
      // Check if the response has content
      const contentType = response.headers.get("content-type");
      let result;

      if (contentType?.includes("application/json")) {
        const responseBody = await response.json();
        result = resultProcessingFunction(responseBody);
      } else {
        // Handle empty response (typical for DELETE)
        result = resultProcessingFunction(null);
      }

      return {
        success: true,
        result,
      };
    }

    // Handle non-ok response
    const errorMessage = `HTTP error! status: ${response.status}`;

    return {
      success: false,
      message: errorMessage,
      result: undefined,
    };
  } catch (e) {
    const error = e instanceof Error ? e : new Error(String(e));

    sendToSentry(error, {
      url,
      method,
      options: { method, body },
      response,
      responseBody,
      additionalContext: {
        component: "fetchUsingApi",
        processingType: typeof resultProcessingFunction,
        phase: response ? "response-processing" : "request",
      },
    });

    return {
      success: false,
      message: error.message,
      result: undefined,
    };
  }
};

export const fetchWithAuth = async (
  url: string,
  options: FetchWithAuthOptions = {},
): Promise<Response> => {
  return Sentry.startSpan(
    {
      name: `Auth ${options.method || "GET"} ${new URL(url).pathname}`,
      op: "http.client.auth",
    },
    async (span) => {
      const accessToken = getToken();
      let response: Response | undefined;

      try {
        const fetchOptions: CustomRequestInit = {
          ...options,
          credentials: "include",
          headers: {
            ...(options.headers || {}),
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
          },
        };

        span?.setAttributes(createSpanAttributes(url, options.method || "GET"));

        response = await fetch(url, fetchOptions);

        if (isClientError(response.status)) {
          const customHandler = options.errorHandlers?.[response.status];
          if (customHandler) {
            const responseBody = await parseResponseBody(response);
            customHandler(response, responseBody);
          } else {
            response = await handleAuthError(url, fetchOptions, span);
          }
        }

        if (span) {
          updateSpanWithResponse(span, response);
        }

        return response;
      } catch (error) {
        const err = error instanceof Error ? error : new Error(String(error));

        // Skip sending standard auth errors to Sentry
        if (
          err.message !== "Authentication failed after token refresh attempt"
        ) {
          sendToSentry(err, {
            url,
            method: options.method,
            options,
            additionalContext: {
              component: "fetchWithAuth",
              accessTokenPresent: Boolean(accessToken),
              requestContext: options.requestContext,
            },
          });
        }

        throw err;
      }
    },
  );
};

async function handleAuthError(
  url: string,
  fetchOptions: CustomRequestInit,
  parentSpan?: Sentry.Span,
): Promise<Response> {
  return Sentry.startSpan(
    {
      name: "Handle Auth Error",
      op: "http.client.auth.refresh",
      parentSpan: parentSpan,
    },
    async (span) => {
      try {
        const refreshed = await refreshToken();

        if (refreshed) {
          const newAccessToken = getToken();
          if (!newAccessToken) {
            throw new Error("Token refresh succeeded but no new token found");
          }

          const newResponse = await fetch(url, {
            ...fetchOptions,
            headers: {
              ...(fetchOptions.headers || {}),
              Authorization: `Bearer ${newAccessToken}`,
            },
          });

          if (span) {
            updateSpanWithResponse(span, newResponse);
          }

          return newResponse;
        }

        // Handle failed refresh
        removeToken();

        // Get current path from router
        const currentPath = routerInstance?.state.location.pathname;

        // Define public routes that don't require redirection
        const publicRoutes = [
          "/login",
          "/reset-password",
          "/forgot-password",
          "/change-email",
          "/login/about",
        ];

        // Only redirect if user is not on a public route
        if (
          routerInstance &&
          currentPath &&
          !publicRoutes.some((route) => currentPath.startsWith(route))
        ) {
          routerInstance.navigate("/login");
        }

        throw new Error("Authentication failed after token refresh attempt");
      } catch (error) {
        const err = error instanceof Error ? error : new Error(String(error));

        if (
          err.message !== "Authentication failed after token refresh attempt"
        ) {
          sendToSentry(err, {
            url,
            method: fetchOptions.method,
            options: fetchOptions,
            additionalContext: {
              component: "handleAuthError",
              phase: "token-refresh",
            },
          });
        }

        throw err;
      }
    },
  );
}

export const refreshToken = async (): Promise<boolean> => {
  return Sentry.startSpan(
    {
      name: "Refresh Token Request",
      op: "http.client.auth.refresh",
    },
    async (span) => {
      let response: Response | undefined;

      try {
        response = await fetch(`${apiBase}/auth/refresh`, {
          method: "POST",
          credentials: "include",
        });

        if (span) {
          updateSpanWithResponse(span, response);
        }

        if (response.ok) {
          const data = (await response.json()) as AuthResponse;
          if (data.accessToken) {
            setToken(data.accessToken);
            return true;
          }
        }

        return false;
      } catch {
        // const err = error instanceof Error ? error : new Error(String(error));

        // // Log unsuccessful refresh attempts
        // sendToSentry(err, {
        //   url: `${apiBase}/auth/refresh`,
        //   method: "POST",
        //   response,
        //   responseBody,
        //   additionalContext: {
        //     component: "refreshToken",
        //     phase: response ? "response-processing" : "request",
        //   },
        // });

        return false;
      }
    },
  );
};
