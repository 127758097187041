import { useMutation, useQuery } from "@tanstack/react-query";

import { apiBase, fetchUsingApi } from "../core/apiClient";

export interface EntityConfiguration {
  id: number;
  type: EntityConfigurationType;
  entityType: EntityConfigurationEntityType;
  entityId: number;
  value: string;
}
export enum EntityConfigurationEntityType {
  BUILDING = "BUILDING",
  HOUSEHOLDER = "HOUSEHOLDER",
}
export enum EntityConfigurationType {
  QUESTIONS_PROMPT = "QUESTIONS_PROMPT",
  WARRANTY_PROMPT = "WARRANTY_PROMPT",
  PROPERTY_MANAGER_CASE_PROMPT = "PROPERTY_MANAGER_CASE_PROMPT",
  WELCOME_EMAIL = "WELCOME_EMAIL",
  HAUSING_CLIENT_ID = "HAUSING_CLIENT_ID",
  HAUSING_CLIENT_SECRET = "HAUSING_CLIENT_SECRET",
  HAUSING_COMPANY_ID = "HAUSING_COMPANY_ID",
  HAUSING_BUILDING_ID = "HAUSING_BUILDING_ID",
  HAUSING_ROOM_ID = "HAUSING_ROOM_ID",
  TASK_DEFINITION_PROMPT = "TASK_DEFINITION_PROMPT",
  HAUSING_TICKET_DESCRIPTION = "HAUSING_TICKET_DESCRIPTION",
  WARRANTY_AUTO_REPLY_MESSAGE = "WARRANTY_AUTO_REPLY_MESSAGE",
}

export interface ConfigurationUpdateRequest {
  buildingId: number;
  configurationType: EntityConfigurationType;
  configurationValue: string;
}

const getBuildingConfiguration = async (buildingId: number) => {
  const response = await fetchUsingApi<EntityConfiguration[]>(
    `${apiBase}/configuration/v1/list?buildingId=${buildingId}`,
    "GET",
    (e) => e as EntityConfiguration[],
  );

  if (!response.success) {
    throw new Error(response.message || "Failed to fetch configuration");
  }
  console.log(response.result);

  return response.result;
};

const updateConfiguration = async (request: ConfigurationUpdateRequest) => {
  const response = await fetchUsingApi<boolean>(
    `${apiBase}/configuration/v1/update`,
    "POST",
    (e) => e as boolean,
    JSON.stringify(request),
  );

  if (!response.success) {
    throw new Error(response.message || "Failed to update configuration");
  }

  return response.result;
};

export const useBuildingConfiguration = (buildingId: number) => {
  return useQuery({
    queryKey: ["building-configuration", buildingId],
    queryFn: () => getBuildingConfiguration(buildingId),
    enabled: buildingId > 0, // Only fetch if we have a valid building ID
  });
};

export const useUpdateConfiguration = () => {
  return useMutation({
    mutationFn: updateConfiguration,
    // Optional: You can add onSuccess/onError handlers
  });
};
