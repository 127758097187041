import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { setRouterInstance } from "@/api/rest/core/apiClient";
import { AccountPage } from "@/app/account/AccountPage";
import BuildingPage from "@/app/building/BuildingPage";
import BuildingSettingsPage from "@/app/building/BuildingSettingsPage";
import HomePage from "@/app/household/HomePage";
import WarrantyPage from "@/app/household/warranty/WarrantyPage";
import { AuthType } from "@/app/login/LoginType";
import CasePage from "@/app/warranty/CasePage.tsx";
import { PostHogWrapper } from "@/components/analytics/PostHogProvider";
import { ErrorBoundary, RouterErrorBoundary } from "@/components/ErrorBoundary";
import { WebviewInitializer } from "@/utils/webview/WebViewInitializer";

import { CaseType } from "./api/rest/models/warranty.ts";
import Household from "./app/household/HouseholdPage";
import LoggedInPage from "./app/household/LoggedInPage";
import ProductEdit from "./app/household/product/ProductEditPage";
import PropertyManagerCasePage from "./app/household/property-manager/PropertyManagerCasePage.tsx";
import Login from "./app/login/LoginPage";
import UnsubscribePage from "./components/UnsubscribePage";
import "./i18n";
import "./index.css";
import { FILTERED_ERROR_MESSAGES } from "./utils/sentry.ts";

Sentry.init({
  dsn:
    import.meta.env.MODE === "production"
      ? (import.meta.env.VITE_SENTRY_DSN as string)
      : undefined,
  enabled: import.meta.env.MODE === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //     maskAllText: false,
    //     blockAllMedia: false,
    // }),
  ],
  beforeSend: (event: Sentry.ErrorEvent) => {
    // Check if this is an error event
    if (event.exception && event.exception.values) {
      // Check each exception value
      for (const exception of event.exception.values) {
        if (
          FILTERED_ERROR_MESSAGES.some((message) =>
            exception.value?.includes(message),
          )
        ) {
          return null; // Don't send the event
        }
      }
    }
    return event;
  },

  // Tracing
  tracePropagationTargets: ["api.dobu.ee"],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient();

const router = createBrowserRouter([
  { path: "/", element: <Login authType={AuthType.LOGIN} /> },
  {
    path: "/login",
    element: <Login authType={AuthType.LOGIN} />,
  },
  {
    path: "/login/about",
    element: <Login authType={AuthType.ABOUT} />,
  },
  {
    path: "/reset-password",
    element: <Login authType={AuthType.RESET_PASSWORD} />,
  },
  {
    path: "/forgot-password",
    element: <Login authType={AuthType.FORGOT_PASSWORD} />,
  },
  {
    path: "/change-email",
    element: <Login authType={AuthType.CHANGE_EMAIL} />,
  },
  {
    path: "/household/",
    element: <LoggedInPage />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: ":householdId",
        element: <Household />,
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: ":householdId/product/:productId",
        element: <ProductEdit mode={"update"} />,
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: ":householdId/product/new",
        element: <ProductEdit mode={"create"} />,
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: ":householdId/warranty",
        element: <WarrantyPage />,
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: ":householdId/property-manager-case",
        element: <PropertyManagerCasePage />,
        errorElement: <RouterErrorBoundary />,
      },
    ],
  },
  {
    path: "/warranty/",
    element: <LoggedInPage />,
    children: [
      {
        path: ":caseId",
        element: <CasePage type={CaseType.WARRANTY} />,
        errorElement: <RouterErrorBoundary />,
      },
    ],
  },
  {
    path: "/property-manager-case/",
    element: <LoggedInPage />,
    children: [
      {
        path: ":caseId",
        element: <CasePage type={CaseType.PROPERTY_MANAGER_CASE} />,
        errorElement: <RouterErrorBoundary />,
      },
    ],
  },
  {
    path: "/building/",
    element: <LoggedInPage />,
    children: [
      {
        path: ":buildingId",
        element: <BuildingPage />,
        errorElement: <RouterErrorBoundary />,
      },
      {
        path: ":buildingId/settings",
        element: <BuildingSettingsPage />,
        errorElement: <RouterErrorBoundary />,
      },
    ],
  },
  {
    path: "/account/",
    element: <LoggedInPage />,
    children: [
      {
        path: "",
        element: <AccountPage />,
        errorElement: <RouterErrorBoundary />,
      },
    ],
  },
  {
    path: "/unsubscribe/:token",
    element: <UnsubscribePage />,
    errorElement: <RouterErrorBoundary />,
  },
  {
    path: "/not-found",
    element: <LoggedInPage />,
    children: [
      {
        path: "",
        element: <div className="text-xl">Not found</div>,
      },
    ],
  },
]);

setRouterInstance(router);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary>
      <PostHogWrapper>
        <GoogleOAuthProvider clientId="338357809874-90emau8kl6hhg24p1aphpqo7v25n7po1.apps.googleusercontent.com">
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <WebviewInitializer />
          </QueryClientProvider>
        </GoogleOAuthProvider>
      </PostHogWrapper>
    </ErrorBoundary>
  </React.StrictMode>,
);
