// src/components/warranty-contents/CaseStatusDropdown.tsx
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Case, CaseStatus, statusMap } from "@/api/rest/models/warranty";

interface CaseStatusDropdownProps {
  caseData: Case;
  onStatusChange: (status: CaseStatus) => Promise<void>;
  disabled?: boolean;
}

export function CaseStatusDropdown({
  caseData,
  onStatusChange,
  disabled = false,
}: CaseStatusDropdownProps) {
  const { t } = useTranslation();

  // Available statuses to select from
  const availableStatuses = [
    CaseStatus.REVIEW, // "Under Review by Builder"
    CaseStatus.TO_DO, // "To Do"
    CaseStatus.WAITING, // "On Hold"
    CaseStatus.IN_PROGRESS, // "In Progress"
    CaseStatus.SOLVED, // "Completed"
    CaseStatus.CLOSED, // "Closed"
  ];

  // Get the localized status from the status map
  const getLocalizedStatus = (status: CaseStatus) => {
    const statusKey =
      statusMap.get(caseData.type)?.get(status) || status.toString();
    return t(statusKey);
  };

  // Status styling classes
  const getStatusClass = (status: CaseStatus) => {
    switch (status) {
      case CaseStatus.REVIEW:
        return "bg-purple-100 text-purple-800";
      case CaseStatus.TO_DO:
        return "bg-blue-100 text-blue-800";
      case CaseStatus.WAITING:
        return "bg-yellow-100 text-yellow-800";
      case CaseStatus.IN_PROGRESS:
        return "bg-blue-100 text-blue-800";
      case CaseStatus.SOLVED:
        return "bg-green-100 text-green-800";
      case CaseStatus.CLOSED:
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <div className="w-72 inline-flex">
      <Listbox
        value={caseData.status}
        onChange={onStatusChange}
        disabled={disabled}
      >
        <div className="relative">
          <ListboxButton className="relative w-full cursor-default rounded-lg bg-gray-50 py-2 pl-4 pr-10 text-left border border-gray-200 focus:outline-none focus-visible:border-dobu-orange focus-visible:ring-0 text-sm">
            <span className="block truncate">
              {t(caseData.localisedStatus)}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute mt-1 max-h-60 min-w-full w-max overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              {availableStatuses.map((status) => (
                <ListboxOption
                  key={status}
                  className={({ focus }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      focus ? "bg-orange-50" : "text-gray-900"
                    }`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={clsx(
                          "inline-block truncate rounded-full px-3 py-1 font-normal text-sm", // Changed from "block" to "inline-block"
                          selected && "font-medium",
                          getStatusClass(status),
                        )}
                      >
                        {getLocalizedStatus(status)}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-dobu-orange">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
