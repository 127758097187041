import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { clsx } from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationSettings as NotificationSettingsType } from "@/api/rest/models/notification";
import {
  fetchNotificationSettings,
  updateNotificationSettings,
} from "@/api/rest/services/notificationService";
import { Banner } from "@/components/common/Banner";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export function NotificationSettings() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Set up local state to track form changes before submission
  const [localSettings, setLocalSettings] =
    useState<NotificationSettingsType | null>(null);
  const [saveMessage, setSaveMessage] = useState<{
    message: string;
    success: boolean;
  } | null>(null);

  // Fetch notification settings using React Query
  const {
    data: settings,
    isLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ["notifications", "settings"],
    queryFn: async () => {
      const response = await fetchNotificationSettings();
      if (response.success && response.result) {
        // Initialize local state with fetched settings
        setLocalSettings(response.result);
        return response.result;
      }
      throw new Error(
        response.message || "Failed to load notification settings",
      );
    },
    // Don't refetch on window focus to avoid overwriting user's unsaved changes
    refetchOnWindowFocus: false,
  });

  // Set up mutation for saving settings
  const { mutate: saveSettings, isPending: isSaving } = useMutation({
    mutationFn: updateNotificationSettings,
    onSuccess: (response) => {
      if (response.success) {
        // Update the cache with new settings
        queryClient.setQueryData(["notifications", "settings"], localSettings);
        setSaveMessage({
          message: t(
            "account.notificationSettingsSaved",
            "Notification settings saved successfully",
          ),
          success: true,
        });
      } else {
        setSaveMessage({
          message:
            response.message ||
            t(
              "account.notificationSettingsError",
              "Failed to save notification settings",
            ),
          success: false,
        });
      }
    },
    onError: (error) => {
      setSaveMessage({
        message:
          error instanceof Error
            ? error.message
            : t(
                "account.notificationSettingsError",
                "Failed to save notification settings",
              ),
        success: false,
      });
    },
  });

  useDebugLifecycle("NotificationSettings", {
    data: {
      url: window.location.href,
      queryState: { isLoading, isError, hasData: Boolean(settings) },
    },
  });

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-dobu-orange" />
      </div>
    );
  }

  // Show error state
  if (isError || !localSettings) {
    return (
      <div className="p-4 bg-red-100 text-red-700 rounded-md">
        {error instanceof Error
          ? error.message
          : t(
              "account.failedToLoadSettings",
              "Failed to load notification settings",
            )}
      </div>
    );
  }

  const handleDeliveryMethodChange = (
    method: keyof NotificationSettingsType["deliveryMethods"],
  ) => {
    if (!localSettings) {
      return;
    }

    setLocalSettings({
      ...localSettings,
      deliveryMethods: {
        ...localSettings.deliveryMethods,
        [method]: !localSettings.deliveryMethods[method],
      },
    });
  };

  const handleNotificationTypeChange = (
    type: keyof NotificationSettingsType["notificationTypes"],
  ) => {
    if (!localSettings) {
      return;
    }

    setLocalSettings({
      ...localSettings,
      notificationTypes: {
        ...localSettings.notificationTypes,
        [type]: !localSettings.notificationTypes[type],
      },
    });
  };

  const handleSaveChanges = () => {
    if (!localSettings) {
      return;
    }

    setSaveMessage(null);
    saveSettings(localSettings);
  };

  // Check if user has made changes to detect if form is dirty
  const hasChanges =
    settings &&
    localSettings &&
    (settings.deliveryMethods.email !== localSettings.deliveryMethods.email ||
      settings.deliveryMethods.mobileApp !==
        localSettings.deliveryMethods.mobileApp ||
      settings.notificationTypes.maintenanceReminders !==
        localSettings.notificationTypes.maintenanceReminders ||
      settings.notificationTypes.warrantyMessages !==
        localSettings.notificationTypes.warrantyMessages);

  return (
    <div className="flex flex-col">
      {/* Delivery Methods */}
      <div className="mb-6 border-b pb-4">
        <h4 className="text-lg font-semibold mb-2 text-gray-700">
          {t("account.deliveryMethods", "Delivery Methods")}
        </h4>
        <p className="text-sm text-gray-500 mb-3">
          {t(
            "account.deliveryMethodsDesc",
            "How would you like to receive notifications?",
          )}
        </p>

        <div className="flex flex-col gap-2 ml-1">
          <label className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-dobu-orange rounded mr-2"
              checked={localSettings.deliveryMethods.mobileApp}
              onChange={() => handleDeliveryMethodChange("mobileApp")}
            />
            <span>{t("account.mobileApp", "Mobile App")}</span>
          </label>

          <label className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-dobu-orange rounded mr-2"
              checked={localSettings.deliveryMethods.email}
              onChange={() => handleDeliveryMethodChange("email")}
            />
            <span>{t("account.email", "Email")}</span>
          </label>
        </div>
      </div>

      {/* Notification Types */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-3 text-gray-700">
          {t("account.notificationTypes", "Notification Types")}
        </h4>

        <div className="flex flex-col gap-4 ml-1">
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-dobu-orange rounded mr-2"
                checked={localSettings.notificationTypes.maintenanceReminders}
                onChange={() =>
                  handleNotificationTypeChange("maintenanceReminders")
                }
              />
              <span className="font-medium">
                {t("account.maintenanceReminders", "Maintenance Reminders")}
              </span>
            </label>
            <p className="text-sm text-gray-500 ml-7">
              {t(
                "account.maintenanceRemindersDesc",
                "Get notified about upcoming maintenance tasks",
              )}
            </p>
          </div>

          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-dobu-orange rounded mr-2"
                checked={localSettings.notificationTypes.warrantyMessages}
                onChange={() =>
                  handleNotificationTypeChange("warrantyMessages")
                }
              />
              <span className="font-medium">
                {t("account.warrantyMessages", "New Warranty Messages")}
              </span>
            </label>
            <p className="text-sm text-gray-500 ml-7">
              {t(
                "account.warrantyMessagesDesc",
                "Notifications when warranty providers respond to your case",
              )}
            </p>
          </div>
        </div>
      </div>

      {/* Save Button and messages */}
      <div className="mt-4">
        <button
          className={clsx(
            "w-full py-2 rounded-md transition-colors",
            hasChanges
              ? "bg-dobu-orange hover:bg-dobu-orange-focus text-white"
              : "bg-gray-300 text-gray-500 cursor-not-allowed",
          )}
          onClick={handleSaveChanges}
          disabled={isSaving || !hasChanges}
        >
          {isSaving
            ? t("common.saving", "Saving...")
            : t("common.saveChanges", "Save Changes")}
        </button>

        {saveMessage && (
          <div className="mt-3">
            <Banner
              message={saveMessage.message}
              success={saveMessage.success}
            />
          </div>
        )}
      </div>
    </div>
  );
}
