import type { Viewer } from "@photo-sphere-viewer/core";
import { useRef } from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";

import { Document } from "@/api/rest/models/document";
import { getDocumentUrl2 } from "@/api/rest/services/documentService";

import { ViewerProps } from "../types";

export const Sphere360Viewer: React.FC<ViewerProps> = ({
  document,
  onSuccess,
  onError,
  className,
}) => {
  const viewerRef = useRef<Viewer>(null);
  const height = window.matchMedia("(max-width: 768px)").matches
    ? "80vh"
    : "60vh";

  return (
    <div className={className}>
      <ReactPhotoSphereViewer
        src={getDocumentUrl2(document)}
        height={height}
        width="100%"
        onReady={() => {
          try {
            onSuccess();
          } catch {
            // If loading fails, show error using the viewer's API
            viewerRef.current?.showError("Failed to load 360° image");
            onError(new Error("Failed to load 360° image"));
          }
        }}
        // Add basic error handling through the navbar
        navbar={["autorotate", "zoom", "fullscreen"]}
        lang={{
          zoom: "Zoom",
          zoomOut: "Zoom out",
          zoomIn: "Zoom in",
          moveUp: "Move up",
          moveDown: "Move down",
          moveLeft: "Move left",
          moveRight: "Move right",
          download: "Download",
          fullscreen: "Fullscreen",
          menu: "Menu",
          close: "Close",
          twoFingers: "Use two fingers to navigate",
          ctrlZoom: "Use ctrl + scroll to zoom the image",
          loadError: "Failed to load 360° image",
          littlePlanetButton: "Little Planet view",
          littlePlanetIcon: "Little Planet",
        }}
      />
    </div>
  );
};

// Utility function to check if image is in a 360 folder
export const is360Image = (document: Document): boolean => {
  const isImage = ["jpg", "jpeg", "png"].includes(
    document.fileName.split(".").pop()?.toLowerCase() ?? "",
  );
  // Check if the file path contains "/360/" folder
  const is360Folder = document.folderPath.some((folder) => folder === "360");
  return isImage && is360Folder;
};
