import { apiBase, fetchWithAuth } from "@/api/rest/core/apiClient";

import i18n from "../../../i18n";

export enum EmitterDataTag {
  DOCUMENTS = "DOCUMENTS",
}

export interface EmitterData {
  tag: EmitterDataTag;
  data: string;
}

// Create a regex pattern from the enum values
const getEmitterTagPattern = (): string => {
  return Object.values(EmitterDataTag).join("|");
};

// Tags format: [tag:data] - only match specific emitter tags from the enum
const getEmitterRegex = (): RegExp => {
  const tagPattern = getEmitterTagPattern();
  return new RegExp(`\\[(${tagPattern}):([-\\w,]+)\\]`, "g");
};

export const askQuestion = async (
  householdId: number,
  question: string,
  startFresh: boolean,
  streamResponse: (
    response: string,
    isComplete: boolean,
    tagsFound: EmitterData[],
  ) => void,
): Promise<void> => {
  const result = await fetchWithAuth(`${apiBase}/chat/v1/question`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      question,
      householdId,
      startFresh,
    }),
  });
  const reader = result.body?.getReader();
  let response = "";
  const foundTags: EmitterData[] = [];

  while (reader) {
    const { done, value } = await reader.read();
    if (done) {
      // Process any final tags in the response
      const finalTags = getEmitterData(response);
      foundTags.push(...finalTags);

      // Remove the tags from the final response text
      response = cleanResponseText(response);

      streamResponse(response, true, foundTags);
      return;
    }

    const decodedResponse = new TextDecoder().decode(value);

    // Extract any tags from the current chunk
    const newTags = getEmitterData(decodedResponse);
    foundTags.push(...newTags);

    // Clean the response text of any tags
    const cleanedResponse = cleanResponseText(decodedResponse);
    response += cleanedResponse;

    streamResponse(response, false, foundTags);
  }

  throw i18n.t("common.error.500");
};

const getEmitterData = (response: string): EmitterData[] => {
  const emitterRegex = getEmitterRegex();
  const matches = [...response.matchAll(emitterRegex)];
  return matches.map(([, tag, data]) => ({
    tag: tag as EmitterDataTag,
    data: data,
  }));
};

// Helper function to remove only emitter tags from response text
const cleanResponseText = (text: string): string => {
  const emitterRegex = getEmitterRegex();
  return text.replace(emitterRegex, "");
};
