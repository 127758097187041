import { clsx } from "clsx";
import { ReactNode, useEffect } from "react";

import { useDialogue } from "@/api/hooks/useDialogue.ts";
import UnmanagedPopup from "@/components/common/UnmanagedPopup.tsx";

import { PopupContentSize } from "./PopupSizeUtils";

export default function ManagedPopup({
  cleanup,
  trigger,
  triggerClassname,
  title,
  children,
  onClose,
  onStateChange,
  contentSize,
  className,
}: {
  trigger: ReactNode;
  title: ReactNode;
  children: ReactNode;
  triggerClassname?: string;
  cleanup?: () => void;
  onClose?: () => void;
  contentSize?: PopupContentSize;
  onStateChange?: (isOpen: boolean) => void;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useDialogue(cleanup);

  useEffect(() => {
    onStateChange?.(isOpen);
  }, [isOpen, onStateChange]);

  return (
    <UnmanagedPopup
      className={className}
      trigger={
        <span
          className={clsx("cursor-pointer", triggerClassname)}
          onClick={() => setIsOpen(true, true)}
        >
          {trigger}
        </span>
      }
      title={title}
      isOpen={isOpen}
      closePopup={() => {
        setIsOpen(false, true);
        if (onClose) {
          onClose();
        }
      }}
      contentSize={contentSize}
    >
      {children}
    </UnmanagedPopup>
  );
}
